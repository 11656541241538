<template>
  <pro-menu-layout>
    <div class="q-pa-md">
      <pro-deck
        :title="getRes('Form.Section.TenderClarification')"
        :cards="tendererList"
        hide-count-badge
        v-slot="{ card }"
      >
        <pro-deck-card :title="card.title" :alias="card.alias" expandable>
          <pro-deck-card-time-map :entries="card.clarifications" />
        </pro-deck-card>
      </pro-deck>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProDeckCardTimeMap from "@/components/PROSmart/Deck/Body/ProDeckCardTimeMap";

export default {
  components: { ProMenuLayout, ProDeck, ProDeckCard, ProDeckCardTimeMap },
  data() {
    return {
      tendererList: [],
    };
  },
  methods: {
    getLastSubmissionDateStr(clarification) {
      return (
        this.$t("Form.Field.LastSubmission") +
        " " +
        (clarification.dateResponded
          ? this.$proSmart.common.getFormattedDate(
              new Date(clarification.dateResponded)
            )
          : "---")
      );
    },
  },
  created() {
    this.$proSmart.tender
      .getTenderClarificationLatestResponseList(this, this.$route.params.id)
      .then((tendererList) => {
        this.tendererList = tendererList.map((tenderer) => ({
          key: tenderer.tendererId,
          title: tenderer.name,
          alias: tenderer.ref,
          clarifications: tenderer.tenderClarifications.map(
            (clarification) => ({
              key: clarification.queryId,
              title: clarification.description,
              label: this.getLastSubmissionDateStr(clarification),
              action: {
                label: this.getRes("System.Button.View"),
                to: {
                  name: "DocumentViewTenderClarificationDetails",
                  params: {
                    queryId: clarification.queryId,
                    processId: clarification.processId,
                    tendererId: tenderer.tendererId,
                    stepperId: this.$route.params.stepperId,
                  },
                },
              },
            })
          ),
        }));
      });
  },
};
</script>
